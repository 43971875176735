.upload__img-close {
  position: absolute;
  top: 4%;
  /* Adjust as needed */
  right: 5%;
  /* Adjust as needed */
  cursor: pointer;
  color: black
}

.upload__img-close:hover {
  /* Optional: Add visual feedback on hover */
  background-color: white(0, 0, 0, 0.1);
}

/* Add this to your CSS */
.larger-image {
  max-height: 520px;
  /* Adjust the height as needed */
  margin: auto;
  /* Center the image horizontally */
  /* max-width: 900px; */
}

.download-link {
  display: block;
  text-align: center;
  margin-top: 10px;
  /* Adjust the margin as needed */
}