.add-single-record-btn-wrapper {
    display: inline-block;
    position: absolute;
    right: 1%;
}

.add-single-record-btn {
    background-color: rgb(255, 131, 0);
    display: inline-block;
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px; /* Adjust the max-width as per your need */
    display: inline-block;
}
