.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#data-upload-screen th {
  white-space: nowrap !important;
  min-width: 200px !important;
}

.modal-dialog.modal-800 {
  width: 800px;
  margin: 30px auto;
  max-width: 800px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* styles */

/* breadcrumb css start */

.breadcrumb.wizard {
  padding: 0px;
  background: #D4D4D4;
  list-style: none;
  overflow: hidden;
  margin-top: 20px;
  font-size: 10px;
}

.breadcrumb.wizard>li+li:before {
  padding: 0;
}

.breadcrumb.wizard li {
  float: left;
}

.breadcrumb.wizard li.active a {
  background: brown;
  /* fallback color */
  background: #ffc107;
}

.breadcrumb.wizard li.completed a {
  background: brown;
  /* fallback color */
  background: #FF0000;
}

.breadcrumb.wizard li.active a:after {
  border-left: 30px solid #ffc107;
}

.breadcrumb.wizard li.completed a:after {
  border-left: 30px solid #FF0000;
}

.breadcrumb.wizard li a {
  color: white;
  text-decoration: none;
  padding: 10px 0 10px 45px;
  position: relative;
  display: block;
  float: left;
}

.breadcrumb.wizard li a:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid hsla(0, 0%, 83%, 1);
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}

.breadcrumb.wizard li a:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  /* Go big on the size, and let overflow hide */
  border-bottom: 50px solid transparent;
  border-left: 30px solid white;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 1px;
  left: 100%;
  z-index: 1;
}

.breadcrumb.wizard li:first-child a {
  padding-left: 15px;
}

.breadcrumb.wizard li a:hover {
  background: #ffc107;
}

.breadcrumb.wizard li a:hover:after {
  border-left-color: #ffc107 !important;
}


/* breadcrumb css end */

.datepicker {
  padding: .4375rem .75rem !important;
  border-radius: .25rem !important;
}

.panel-heading .accordion-toggle:after {
  /* symbol for "opening" panels */
  font-family: 'Font Awesome 5 Free';
  /* essential for enabling glyphicon */
  content: "\f053";
  /* adjust as needed, taken from bootstrap.css */
  float: right;
  /* adjust as needed */
  color: grey;
  /* adjust as needed */
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}

.panel-heading .accordion-toggle.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f054";
  /* adjust as needed, taken from bootstrap.css */
}

/* css */


table th {
  white-space: nowrap;
}


.bind-width {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  display: inline-block;
}

.form-text-error-text {
  color: #ef5350 !important
}

.add-brand-platform {
  z-index: 3000 !important;
}

/* sorting css */

.sort-icon {
  cursor: pointer;
}

noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.sort-icon.sort-icon-asc,
.sort-icon.sort-icon-desc {
  color: #ff6d00;
}

.sort-icon-nutral:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0dc";
  margin-left: .5em;
}

.sort-icon-asc:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0de";
  margin-left: .5em;
}

.sort-icon-desc:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0dd";
  margin-left: .5em;
}

.main-sidebar .dropdown-menu .dropdown-item {
  background: #a1a1;
}

/* popover */

.popover {
  max-width: 300px !important;
  width: 300px !important;
}

.dropdown-item a {
  text-decoration: none !important;
}

.dropdown-item a small {
  color: #a1a1a1;
}

/* th text uppercase */

.uppercase-th th {
  text-transform: uppercase;
}

.uppercase-text {
  text-transform: uppercase;
}

.capitalize-text {
  text-transform: capitalize;
}

.btn-ldotr {
  border: 1px solid red;
  color: red;
}

.fixedElement {
  background-color: #c0c0c0;
  position: fixed;
  top: 0;
  width: 80%;
  z-index: 100;
}

.notification-highlighted-text {
  font-weight: 500;
  font-style: italic;
}

.notification-time {
  color: #a1a1a1;
}

.filter-parent-tag {
  cursor: pointer !important;
  color: orange;
}

.duplicate-error .form-group .form-control {
  border: 1px solid red;
}

.duplicate-errors {
  border: 1px solid red;
}
.table .inline {
  display: inline;
}

/* for brand list in topNav.js */

.brand-dropdown {
  overflow-y: auto;
  max-height: 300px;
  /* width: 400px !important; */
  /* left: -19em */
}

.min-width-200-px {
  min-width: 200px !important;
}

.brand-search-input {
  border-radius: 3px !important;
}

.custom-dropdown-item {
  padding: 5px;
}

.wrap {
  word-wrap: break-word;
}

.href-beautify {
  font-family: helvetica;
  text-decoration: none;
  color: #282c34
}

.href-beautify:hover {
  color: black;
}

.textbox-dropdown {
  padding: 0px 5px;
  margin: 0;
}

canvas{
  height:300px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.position-fixed{
  display: contents !important;
}

.hover-highlighted:focus, .hover-highlighted:hover {
  background: #FFEBEE !important;
}

.no-records-found {
  text-align: center;
}

.no-records-found::after{
    content: "";
    display: inline-block;
    max-width: 300px;;
    width: 300px;
    height: 300px;
    background: url('./assets/images/no-records-found.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}