
.table-custom{
    background-color:#fff !important;
    border-collapse: separate;
    border-radius: .3rem;
    border-spacing: 0;
    border-top: .2rem solid #d8dbe0;
    max-width: 100%;
    padding: .5rem;
    width: 100%;
}

.table-custom tr, 
.table-custom td, 
.table-custom thead,
.table-custom th {
    border-bottom: none !important;
}

.table-custom thead  tr, th{
    border-top:none !important;
}

.card-body-table {
    padding: 0 !important;
}