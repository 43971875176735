.align {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.align-6-items {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.hor-ver-center {
  place-self: end center;
}

.pos-right {
  place-self: start center;
}

.align,
.align-6-items {
  margin-top: 5px;
  display: grid;
  grid-template-rows: 1fr;
}

.sticky-div-user {
  position: sticky;
  top: 6%; /* Stick to the top of the viewport */
  background-color: white; /* Adjust as per your design */
  z-index: 1000;
}

.sticky-div-brand {
  position: sticky;
  top: 6%; /* Stick to the top of the viewport */
  background-color: white; /* Adjust as per your design */
  z-index: 1000;
}

#brand-table thead {
  position: sticky;
  top: 14%;
  z-index: 1; /* Ensures it stays above other content */
}

#company-table thead {
  position: sticky;
  top: 14%;
  z-index: 1; /* Ensures it stays above other content */
}

#user-table thead {
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  top: 14%;
  /* Adjust based on your layout */
  z-index: 2;
  background: white;
}

#user-table tbody {
  position: relative;
  z-index: 1;
}

/* Fix column alignment */
#user-table thead th {
  background: #f8f9fa;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
}
