@media print {
  button,
  .btn,
  .d-inline-flex {
    display: none !important;
  }

  .position-relative {
    padding: 0 !important;
    width: 100% !important;
    height: auto !important;
  }

  svg {
    width: 100% !important;
    height: auto !important;
  }

  .recharts-legend-item {
    font-size: 10px !important; /* Reduce text size */
  }

  .recharts-legend-item .recharts-surface {
    width: 8px !important; /* Smaller icon width */
    height: 8px !important; /* Smaller icon height */
  }

  .recharts-legend-item-text {
    font-size: 8px !important; /* Adjust legend text size */
  }
}
