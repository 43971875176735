.table-responsive.fix-height {
    max-height: 400px !important;
}

.table-responsive.fix-height table thead tr th {
    text-align: left;
    position: sticky;
    top: -1px;
    background: #fff ;
}

.custom-width-th-40px{
    min-width: 40px !important;
}

/* LDOTR-1 */
.duplicate-errors {
    border: 1px solid red;
}