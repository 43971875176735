.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 5vh auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

/* form */
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #a1a1a1;
  border-radius: 0;
}

.form-signin .form-control:focus {
  box-shadow: none;
  border-color: #f8433f !important;
}

.form-signin label {
  color: #6c757d !important;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.side-background {
  background-image: url('../assets/images/login-page-background.jpg');
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100vh;
}

.login-form-section {
  text-align: center;
}

.logo-section {
  background-image: url("https://static.wixstatic.com/media/4b3d62_126554258c084f21b9d716f78be9c4c0~mv2.png/v1/fill/w_150,h_101,al_c,lg_1,q_85/4b3d62_126554258c084f21b9d716f78be9c4c0~mv2.webp");
  width: 150px;
  height: 100px;
  background-repeat: no-repeat;
  display: inline-block;
  margin-top: 10vh;
}

.form-floating {
  text-align: left !important;
}

.login-btn {
  background: #f8433f;
  border: #f8433f;
  border-radius: 5px;
  height: 30px;
  width: 50%;
  color: #fff;
}

.text-danger {
  color: #f8433f !important;
}

div.fix-bottom-text {
  position: inherit;
  bottom: 10px;
  margin-top: 5px;
  width: 100%;
  color: #f8433f;
  text-align: center;
}

div.fix-bottom-text a {
  text-decoration: none;
  color: #f8433f;
}

.container {
  margin-top: 1%;
  width: 600px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column
}

.otp-container {
  display: flex;
}

.otp-input {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 24px;
  text-align: center;
  outline: none;
}

.otp-input:focus {
  border-color: #007bff;
}

/* Optional: To add more styling for completed inputs */
.otp-input.completed {
  border-color: #28a745;
}

/* Style for the resend button */
.resend-button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  color: red;
  /* Text color */
  font-size: 16px;
  /* Font size */
  padding: 0px 5px;
  /* Padding around text */
  display: inline-block;
  /* Make the button inline */
  transition: background-color 0.3s, color 0.3s;
  /* Add a smooth transition effect */
}

button:hover {
  cursor: pointer;
}

/* CSS for red color */
.error {
  border: 2px solid red;
}

/* Reset password -> form button */
.form-resetpassword {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 1vh auto;
}

.form-resetpassword .checkbox {
  font-weight: 400;
}

/* form */
.form-resetpassword .form-control {
  position: relative;
  box-sizing: border-box;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #a1a1a1;
  border-radius: 0;
}

.form-resetpassword .form-control:focus {
  box-shadow: none;
  border-color: #f8433f !important;
}

.form-resetpassword label {
  color: #6c757d !important;
}

.form-resetpassword input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.google-signin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  padding: 10px 0;
  margin: 0px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #5f6368;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.3s ease;
}

.google-signin-button:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.google-signin-button img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.google-signin-button span {
  color: #5f6368;
}

.google-signin-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #aaa;
  margin: 10px 0;
  margin-top: -10px;
}

.google-signin-divider::before,
.google-signin-divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #eee;
  margin: 0 10px;
}

.google-signin-container{
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto
}