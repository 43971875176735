.input-content2 {
  height: 20%;
  width: 95%;
  resize: none;
  border: "3px solid #ccc";
  padding: 10px;
  /* border-radius: 50px; */

}

.larger-image-trap {
  max-height: 400px;
  /* Adjust the height as needed */
  margin: auto;
  /* Center the image horizontally */
  /* max-width: 900px; */
}

.download-link-trap {
  display: block;
  text-align: center;
  margin-top: 10px;
  /* Adjust the margin as needed */
}