/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 450px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
         -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0;
}

.modal.left .modal-body,
.modal.right .modal-body {
    padding: 15px 15px 10px;
}

/*Left*/
.modal.left.fade .modal-dialog{
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
         -o-transition: opacity 0.3s linear, left 0.3s ease-out;
            transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog{
    left: 0;
}
    
/*Right*/
.modal.right.fade .modal-dialog {
    right: -0px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
         -o-transition: opacity 0.3s linear, right 0.3s ease-out;
            transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
    right: 0;
}

.modal.right .modal-header, 
.modal.right .modal-footer {
    color: #fff !important;
    background-color: red;
    border-radius: 0;
}

.modal.right .modal-footer {
    justify-content: flex-start;
    padding: 5px;
    margin: 0;
}

.modal.right .modal-body .form-group > label {
    font-weight: 500;
    font-size: 15px;
}

.modal.right .modal-body .form-group > input,  
.modal.right .modal-body .form-group > select,  
.modal.right .modal-body .form-group > textarea {
    font-size: 15px;
}

.modal.right .modal-header .modal-title, 
.modal.right .modal-header .close {
    color: #fff !important;
    font-size: 18px;
}

.font-12{
    font-size: 12px;
}

.toggle-view-type i {
    cursor: pointer;
}

.toggle-view-type i:hover {
    color: red;
}

.toggle-view-type i.selected{
    color: red;
}

.domain-actions button:focus { outline: none; }