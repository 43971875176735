.input-box {
    height: 800px;
    overflow-y: auto;
  }
  
  .input-content {
    height: 50%;
    width: 95%;
    resize: none;
    border: "3px solid #ccc";
    padding: 10px;
    /* border-radius: 50px; */
  
  }
  
